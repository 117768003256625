export default class StorageService {
	// itemPrefix is name to prefix items storage in local storage to avoid possibility of duplicate names
	constructor(itemPrefix = 'zs__') {
		this.itemPrefix = itemPrefix;
	}

	getItem(propName) {
		const itemName = this.itemPrefix + propName;

		try {
			return JSON.parse(localStorage.getItem(itemName));
		}
		// account for item being undefined possibly
		catch(err) {
			return null;
		}
	}

	removeItem(propName) {
		const itemName = this.itemPrefix + propName;
		localStorage.removeItem(itemName);
	}


	setItem(propName, value) {
		const itemName = this.itemPrefix + propName;
		localStorage.setItem(itemName, JSON.stringify(value));
	}




}