import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Alert } from 'react-bootstrap';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

import { authService } from 'services';
import './login.scss';
import { validateEmail } from 'utils';

export default class Login extends Component {
  constructor(props) {
    super(props);

    // this allows react-router to pass state to login component
    const { state: locationState } = props.location;

    const defaultState = {
      message: '',
      isLoading: false,
      messageVariant: '',
      showAlert: true,
      // when successful login, navigate to home page
      shouldNavigateHome: false,
    };

    this.state = { ...defaultState, ...locationState };
    this.history = props.history;
  }

  render() {
    const loginSchema = Yup.object().shape({
      // this was having performance issues, noticeable delay for first keystroke
      // switched to custom validation..
      // email: Yup.string().email().required(),
      password: Yup.string().required(),
    });

    if (this.state.shouldNavigateHome) {
      return <Redirect to="/home" />;
    } else
      return (
        <div className="login-container container">
          <h1>Please Login</h1>

          <div className="alert-container">
            <Alert
              variant={this.state.messageVariant}
              dismissible
              show={this.state.message.length > 0 && this.state.showAlert}
              onClose={() => this.setState({ showAlert: false })}
            >
              <span>{this.state.message}</span>
            </Alert>
          </div>

          <Formik
            validationSchema={loginSchema}
            onSubmit={this.onFormSubmit}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            {({ handleSubmit, isValid }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    validate={validateEmail}
                  />
                  <ErrorMessage name="email">
                    {msg => <div className="invalid-feedback">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="form-group">
                  <label>Password</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage name="password">
                    {msg => <div className="invalid-feedback">{msg}</div>}
                  </ErrorMessage>
                </div>

                <button type="submit" className="btn btn-primary" disabled={!isValid}>
                  {this.state.isLoading ? 'Loading' : 'Submit'}
                </button>
              </form>
            )}
          </Formik>
          <div className="register-wrapper">
            <p className="text-muted">
              No account yet? <Link to="/register">Sign up for one here.</Link>
            </p>
          </div>
        </div>
      );
  }

  onFormSubmit = formValues => {
    const { email, password } = formValues;
    this.setState({ isLoading: true });

    authService
      .login(email, password)
      .then(response => {
        const { data: loginData } = response;
        const { token } = loginData;

        authService.saveToken(token);

        this.setState({ shouldNavigateHome: true });
      })
      .catch(err => {
        this.setState({
          message: err.message,
          showAlert: true,
          messageVariant: 'warning',
        });
      })
      .finally(_ => {
        this.setState({ isLoading: false });
      });
  };

  updateLoginValue = propName => event => {
    this.setState({ [propName]: event.target.value });
  };
}
