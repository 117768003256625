import React from 'react';
import { useQuery } from 'react-query';

import { cameraService } from 'services';
import { format } from 'date-fns';

import './home.scss';

export default function Home({ history }) {
  const { status, error, data: cameras } = useQuery('cameras', () =>
    cameraService.getCameras().then(response => response.data)
  );

  function onCameraSelect(camera) {
    history.push(`/home/${camera._id}`);
  }

  if (status === 'loading') {
    return <p>loading</p>;
  }

  if (error) {
    return <p>error</p>;
  }

  return (
    <div className="home-container container">
      <h1>Home</h1>

      <div className="row ">
        {cameras.length !== 0 &&
          cameras.map((camera, index) => (
            <div className="col-md-4" key={index}>
              <div className="card" onClick={() => onCameraSelect(camera)}>
                <div className="card-body">
                  <h5 className="card-title">{camera.name}</h5>

                  <p className="card-text">
                    Camera ID: <code>{camera.cameraId}</code>
                  </p>
                  <p className="card-text">
                    Mongo ID: <code>{camera._id}</code>
                  </p>
                  <p className="card-text">
                    Created: {format(new Date(camera.createdAt), 'M/D/YY')}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
