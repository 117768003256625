import { apiService } from 'services';

export default class CameraService {
	// constructor() {

	// }


	getCameras() {
		return apiService.get('/cameras');
	}

	findById(cameraId) {
		return apiService.get(`/cameras/${cameraId}`);
	}


	// startDate and endDate received as javascript date objects
	getDataEvents(cameraId, startDate, endDate, dataEventOffset) {
		const startDateString = startDate.toISOString();
		const endDateString = endDate.toISOString();

		const url = `/cameras/${cameraId}/dataEvents?startDate=${startDateString}&endDate=${endDateString}&offset=${dataEventOffset}`;

		return apiService.get(url);
	}

	// get stats, like total count, etc of data events
	getDataEventStats(cameraId, startDate, endDate) {
		const startDateString = startDate.toISOString();
		const endDateString = endDate.toISOString();

		const url = `/cameras/${cameraId}/dataEvents/stats?startDate=${startDateString}&endDate=${endDateString}`;

		return apiService.get(url);
	}


}