import React, { Component } from 'react';

import { dataEventService } from 'services';


import './dataEvent.scss';

export default class DataEvent extends Component {
	constructor({ match }) {
		super();

		this.match = match;

		const { cameraId, dataEventId } = match.params;

		this.cameraId = cameraId;
		this.dataEventId = dataEventId;

		this.state = {
			isLoadingDataEvent: true,
			dataEvent: {},
		};
	}


	render() {
		const { dataEvent } = this.state;
		return this.state.isLoadingDataEvent ? null : (
			<div className="container data-event-container">
				<h4>Data Event Page</h4>

				<div className="data-event-info row">
					<div className="col-md-8 image-container">
						<img src={this.state.dataEvent.imageUrl} alt="" />

					</div>
					<div className="col-md-4 summary-container">
						<p>License Plate: {dataEvent.plateId}</p>
						<p>Seen: {dataEvent.seen ? 'Yes' : 'No'}</p>
						<p>Trusted: {dataEvent.trusted ? 'Yes' : 'No'}</p>
						<p>Direction: {dataEvent.direction}</p>
					</div>
				</div>


				<div className="raw-images row">
					{dataEvent.rawImages.map((rawImg, index) => {
						return (
							<div
								className="col-lg-4 col-md-6 col-sm-12 raw-image"
								key={index}
							>
								<img alt="raw" src={rawImg.image} />
							</div>
						)
					})}
				</div>
			</div>

		)
	}

	componentDidMount() {
		Promise.all([
			dataEventService.getDataEvent(this.cameraId, this.dataEventId)
		])
			.then(([{ data: dataEvent }]) => {
				this.setState({ dataEvent });
			})
			.catch(console.error)
			.finally(() => this.setState({ isLoadingDataEvent: false }))
	}


}