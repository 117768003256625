import { rest } from 'msw'

import mockCameras from './mock-data/cameras'
import mockDataEvents from './mock-data/dataEvents'

export const handlers = [
  // can turn this on when
  // rest.post('*/login', (req, res, ctx) => {
  //   console.log('intercepted!')
  //   return res(
  //     ctx.delay(1500),
  //     // Respond with a 500 status code
  //     ctx.status(500),
  //     ctx.json({
  //       message: 'messed up..',
  //     })
  //   )
  // }),

  rest.get('*/cameras', (req, res, ctx) => {
    return res(ctx.json(mockCameras))
  }),
  // use a regex to match any camera id dataEvents route
  rest.get(/cameras\/[a-z0-9]+\/dataEvents/, (req, res, ctx) => {
    return res(ctx.json(mockDataEvents))
  }),
]
