import { apiService } from 'services';

export default class UserService {
	verifyEmail(userId, verificationToken) {
		const url = `/auth/verifyEmail`;

		return apiService.put(url, { userId, verificationToken })



	}



}