import React, { Component } from 'react';
import { format, isValid as isValidDate } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import gear from 'images/gear.svg';
import { throttle } from 'utils';
import { cameraService } from 'services';
import './camera.scss';

import queryString from 'query-string';

export default class Camera extends Component {
	constructor({ match, history, location }) {
		super();

		this.match = match;
		this.history = history;
		this.cameraId = match.params.cameraId;

		const now = new Date();
		this.minuteInMs = 1000 * 60;
		this.hourInMs = 1000 * 3600;
		this.dayInMs = this.hourInMs * 24;
		this.weekInMs = this.dayInMs * 7;
		this.monthInMs = this.dayInMs * 30;

		// num dataevents to load at a time
		this.dataEventBatchSize = 10;
		// num ms to throttle the onScroll event
		this.scrollThrottle = 150;

		this.state = createInitialState.call(this);

		// creates initial state
		// if there are url params, it will parse those, but otherwise has default state
		function createInitialState() {
			const defaultState = {
				camera: {},
				dataEvents: [],
				startDate: new Date(Date.parse(now) - this.dayInMs),
				endDate: now,
				imageView: false,
				dataEventOffset: 0,

				isLoadingDataEvents: false,
				isAddingMoreDataEvents: false,

				selectedTimePreset: '1day',

				dataEventStats: [],

				// time presets.  name must be unique field, is way to search for them
				timePresets: [
					{ display: '15m', name: '15minutes', timeDifference: this.minuteInMs * 15 },
					{ display: '1h', name: '1hour', timeDifference: this.hourInMs * 1 },
					{ display: '3h', name: '3hours', timeDifference: this.hourInMs * 3 },
					{ display: '6h', name: '6hours', timeDifference: this.hourInMs * 6 },
					{ display: '12h', name: '12hours', timeDifference: this.hourInMs * 12 },
					{ display: '1d', name: '1day', timeDifference: this.dayInMs },
					{ display: '1w', name: '1week', timeDifference: this.weekInMs },
					{ display: '4w', name: '4weeks', timeDifference: this.weekInMs * 4 },
					{ display: 'Max', name: 'max', timeDifference: null },

				],
			}
			let stateToOverride = {}

			// url params either have both startDate and endDate, or selectedTimePreset, but can't (shouldn't..) have both
			const { startDate: startDateString, endDate: endDateString, selectedTimePresetName, imageView } = queryString.parse(this.history.location.search);

			const selectedPreset = defaultState.timePresets.find(preset => preset.name === selectedTimePresetName);

			if (imageView) {
				// convert imageView string ("true" / "false") to boolean
				stateToOverride = { imageView: imageView === 'true' }
			}

			if (selectedPreset) {
				stateToOverride = {
					...stateToOverride,
					selectedTimePreset: selectedPreset.name,
					startDate: new Date(Date.now() - selectedPreset.timeDifference),
					endDate: new Date(),
				}
			}

			const startDate = new Date(startDateString);
			const endDate = new Date(endDateString);

			// only replace default start/end dates if both of url param dates are valid
			// otherwise weird behavior could occur
			if (isValidDate(startDate) && isValidDate(endDate)) {
				stateToOverride = { ...stateToOverride, startDate, endDate, selectedTimePreset: null }
			} else {
				// just clean up url to avoid confusion
				this.updateSearchParams({ startDate: null, endDate: null })
			}

			return { ...defaultState, ...stateToOverride };
		}
	}


	// returns array of stats to be used by view
	createDataEventStatsArray(dataEventStatsObj) {
		const firstEventDate = new Date(dataEventStatsObj.firstEventDate);
		const firstEventDateFormatted = format(firstEventDate, 'M/D/YY');

		const statsArray = [
			{ label: 'Num Total', fieldName: 'numTotal', value: dataEventStatsObj.numTotal },
			{ label: 'Num Inbound',  value: dataEventStatsObj.numInbound },
			{ label: 'Num Outbound', value: dataEventStatsObj.numOutbound },
			{ label: 'First Event',  value: firstEventDateFormatted },
		];

		// include firstEventDate as property for convenience
		statsArray.firstEventDate = firstEventDate;

		return statsArray;
	}

	componentDidMount() {
		window.onscroll = throttle(this.scrollThrottle, this.handleInfiniteScroll);
		this.loadInitialData();
	}

	loadInitialData = () => {
		const { startDate, endDate } = this.state;
		this.setState({ isLoadingDataEvents: true });

		Promise.all([
			cameraService.findById(this.cameraId),
			cameraService.getDataEvents(this.cameraId, startDate, endDate, this.state.dataEventOffset),
			cameraService.getDataEventStats(this.cameraId, startDate, endDate),
		])
		.then(([{ data: camera }, { data: dataEvents }, { data: dataEventStatsObj }]) => {
			const dataEventStats = this.createDataEventStatsArray(dataEventStatsObj);
			this.setState({ camera, dataEvents, dataEventStats });
		})
		// the next 2 then blocks are only relevant for if selected time preset is "max"
		// additional logic needed, like re-loading events / stats with updated start date
		.then(_ => {
			if (this.state.selectedTimePreset !== 'max') return;

			const startDate = new Date(this.state.dataEventStats.firstEventDate);
			this.setState({ startDate });
			return Promise.all([
				cameraService.getDataEvents(this.cameraId, startDate, endDate, this.state.dataEventOffset),
				cameraService.getDataEventStats(this.cameraId, startDate, endDate),
			])
		})
		.then(results => {
			if (!results) return;

			const [{ data: dataEvents }, { data: dataEventStatsObj }] = results;
			const dataEventStats = this.createDataEventStatsArray(dataEventStatsObj);
			this.setState({ dataEvents, dataEventStats });
		})
		.catch(console.error)
		.finally(() => this.setState({ isLoadingDataEvents: false }));
	}

	// sets up infinite scroll, loading more data events
	handleInfiniteScroll = () => {
		if (this.state.isLoadingMoreDataEvents) return;
		// document height is full height of page
		const documentHeight = document.body.offsetHeight;

		const scrolledDistance = window.innerHeight + window.pageYOffset;

		// distance from bottom of page until infinite scroll kicks in
		const scrollThreshold = 800;

		// when user has scrolled to within 800px (scrollThreshold) of bottom of page
		// needs to load more dataEvents
		if (scrolledDistance + scrollThreshold > documentHeight) {
			this.loadMoreDataEvents();
		}
	}

	// when infinite scroll is triggered, to load more data events
	loadMoreDataEvents() {
		if (this.isLoadingDataEvents) return;

		const newOffset = this.state.dataEventOffset + this.dataEventBatchSize;

		this.setState({ dataEventOffset: newOffset, isLoadingMoreDataEvents: true });

		cameraService.getDataEvents(this.cameraId, this.state.startDate, this.state.endDate, newOffset)
			.then(({ data: moreDataEvents }) => {
				this.setState({ dataEvents: [...this.state.dataEvents, ...moreDataEvents] });
			})
			.catch(console.error)
			.finally(() => this.setState({ isLoadingMoreDataEvents: false }))
	}


	// when user selects a time preset like "1d", "1w" etc
	onPresetSelect = (preset) => () => {
		// handle if preset is max, which is edge condition
		const startDate = (preset.name === 'max')
			? this.state.dataEventStats.firstEventDate || new Date()
			: new Date(Date.now() - preset.timeDifference);
		const endDate = new Date();

		this.setState({ selectedTimePreset: preset.name, startDate, endDate });
		this.updateDataEvents(startDate, endDate);
		this.updateSearchParams({
			startDate: null,
			endDate: null,
			selectedTimePresetName: preset.name,
		})
	}


	// fetches completely new data events, and stats
	// as opposed to loading "more" data events which is used for infinite scroll behavior
	updateDataEvents(startDate = this.state.startDate, endDate = this.state.endDate) {
		// offset goes to 0 when updating data events
		const newOffset = 0;

		this.setState({ isLoadingDataEvents: true, dataEventOffset: newOffset });

		Promise.all([
			cameraService.getDataEvents(this.cameraId, startDate, endDate, newOffset),
			cameraService.getDataEventStats(this.cameraId, startDate, endDate),
		])
		.then(([{ data: dataEvents }, { data: dataEventStatsObj }]) => {
			const dataEventStats = this.createDataEventStatsArray(dataEventStatsObj);
			this.setState({ dataEvents, dataEventStats })
		})
		.catch(console.error)
		.finally(() => this.setState({ isLoadingDataEvents: false }))
	}

	handleStartDateChange = (newDate) => {
		const startDate = newDate;
		let endDate;
		// have conditional for setting date
		if (newDate > this.state.endDate) {
			endDate = newDate;
			this.setState({ endDate })
		}
		const endDateToUse = endDate || this.state.endDate;

		this.setState({ startDate, selectedTimePreset: null });
		this.updateDataEvents(startDate, endDateToUse);
		this.updateSearchParams({ startDate: startDate.toISOString(), endDate: endDateToUse.toISOString(), selectedTimePresetName: null })
	}

	// receives object of query params to update
	updateSearchParams(queryParamsObject) {
		const existingQueryObj = queryString.parse(this.history.location.search);
		const combinedQueryObj = { ...existingQueryObj, ...queryParamsObject };

		// filter out null values, to not show on url params
		// ie, in code can set url param to null to remove it from string param
		const filteredObject = Object.keys(combinedQueryObj).reduce((accum, prop) => ({
			...accum,
			...(combinedQueryObj[prop] !== null ? { [prop]: combinedQueryObj[prop] } : {})
		}), {})

		const newParamString = queryString.stringify(filteredObject, { encode: false });
		this.history.replace({ search: newParamString });
	}

	handleEndDateChange = (newDate) => {
		const endDate = newDate;
		let startDate;
		if (newDate < this.state.startDate) {
			startDate = newDate;
			this.setState({ startDate })
		}
		const startDateToUse = startDate || this.state.startDate;

		this.setState({ endDate, selectedTimePreset: null, isLoadingDataEvents: true });
		this.updateDataEvents(startDateToUse, endDate);
		this.updateSearchParams({ startDate: startDateToUse.toISOString(), endDate: endDate.toISOString(), selectedTimePresetName: null })
	}

	onDataEventSelected = (dataEvent) => () => {
		const newUrl = `${this.history.location.pathname}/${dataEvent._id}`;
		this.history.push(newUrl);
	}

	toggleImageView = () => {
		const shouldShowImageView = !this.state.imageView;
		this.setState({ imageView: shouldShowImageView });
		this.updateSearchParams({ imageView: shouldShowImageView })
	}

 	render() {
		return (
			<div>
				<div className="camera-container container">
					<div className="data-container">
						<div className="data-header">
							<div className="items items-left">
								<div className="time-presets">
									{this.state.timePresets.map((preset, index) => (
										<span
											className={this.state.selectedTimePreset === preset.name ? 'selected' : ''}
											onClick={this.onPresetSelect(preset)}
											key={index}
										>
											{preset.display}
										</span>
									))}
								</div>

							</div>
							<div className="items items-right">
								<DatePicker
									selected={this.state.startDate}
									selectsStart
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									onChange={this.handleStartDateChange}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									dateFormat="M/dd/yy h:mm aa"
									timeCaption="Time"
								/>

								<span className="date-arrow"><FontAwesomeIcon icon="long-arrow-alt-right" /></span>

								<DatePicker
									selected={this.state.endDate}
									selectsEnd
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									onChange={this.handleEndDateChange}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									dateFormat="M/dd/yy h:mm aa"
									timeCaption="Time"
								/>
								<button
									className="btn btn-info btn-sm toggle-button"
									onClick={this.toggleImageView}
								>
									{this.state.imageView ? 'Show Data View' : 'Show Image View'}

								</button>
								<span className="spacer"></span>

							</div>
						</div>

						<div className="stats-header">
							{ this.state.dataEventStats.length > 0 && this.state.dataEventStats.map((statItem, index) => (
								<StatsItem
									key={index}
									label={statItem.label}
									value={statItem.value}
								/>
							))}
						</div>

						<div className="data-body">
							<DataEventsContainer
								dataEvents={this.state.dataEvents}
								imageView={this.state.imageView}
								isLoading={this.state.isLoadingDataEvents}
								onDataEventSelect={this.onDataEventSelected}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

// either returns bunch of dataevent cards, or if none exists, will show nicely to user
function DataEventsContainer({ dataEvents, imageView, isLoading, onDataEventSelect }) {
	if (isLoading) {
		return (
			<div className="loading-container">
				<p>Loading...</p>
				<img src={gear} alt="Loading Gear" />
			</div>
		)
	}
	else if (dataEvents.length) {
		return (
			<div className="row events-container">
				{dataEvents.length !== 0 && dataEvents.map((dataEvent, index) => (
					<div className="col-xl-4 col-lg-6" key={index} >
						<DataEventCard
							dataEvent={dataEvent}
							imageView={imageView}
							onDataEventSelect={onDataEventSelect}
						>
						</DataEventCard>
					</div>
				))}
			</div>
		);
	} else {
		return (
			<p>No data events for that time period!  Please try changing your start/end dates.</p>
		)
	}
}



// dumb presentational component that shows dataEvent
function DataEventCard({ dataEvent, imageView, onDataEventSelect }) {
	return (
		<div className="card data-event-card" onClick={onDataEventSelect(dataEvent)}>

			<div className={`body image-view-body ${!imageView ? 'hide' : ''}`}>
				<img alt="data-event" src={dataEvent.imageUrl}></img>
				<p>License plate: { dataEvent.plateId }</p>
			</div>

			<div className={`body data-view-body ${imageView ? 'hide' : ''}`}>
				<p>License plate: {dataEvent.plateId}</p>
				<p>direction: {dataEvent.direction}</p>
				<p>seen: {dataEvent.seen ? 'Yes' : 'No'}</p>
				<p>trusted: {dataEvent.trusted ? 'Yes' : 'No'}</p>
			</div>

			<div className="event-date">
				Occurred: {format(new Date(dataEvent.createdAt), 'M/D/YY hh:mm aa')}
			</div>

		</div>
	)
}

function StatsItem({ label, value }) {
	return (
		<div className="stats-item">
			<h5>{ label }</h5>
			<span>{ value }</span>
		</div>
	)
}
