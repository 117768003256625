import React, { Component } from 'react';
import queryString from 'query-string';

import { Alert } from 'react-bootstrap';


import './verifyEmail.scss';

import { userService } from 'services';


export default class VerifyEmail extends Component {
	state = {
		showAlert: false,
		messageVariant: '',
		message: '',

		verificationToken: '',
		userId: ''
	}

	async componentDidMount() {
		const urlParams = queryString.parse(window.location.search);

		if (!urlParams.userId || !urlParams.verificationToken) {
			this.setState({ showAlert: true, message: 'Invalid URL.', messageVariant: 'danger' });
			return;
		}
		else {
			await this.setState({ ...urlParams });
		}

		userService.verifyEmail(this.state.userId, this.state.verificationToken)
		.then(_ => {
			// navigate to login component, passing state of message to show

			this.props.history.push('/login', { message: 'Registration Successful, please login now to use your account!', messageVariant: 'success' });

		})
		.catch(err => {
			this.setState({ message: err.message, showAlert: true, messageVariant: 'warning' })
		})









	}

	initializeUrlParams() {


	}

	render() {
		return (
			<div className="verify-email-container container">

				<h1>verify email</h1>

				<div className="alert-container">
					<Alert
						variant={this.state.messageVariant} dismissible
						show={this.state.message.length > 0 && this.state.showAlert}
						onClose={() => this.setState({ showAlert: false })}
					>
						<span>{this.state.message}</span>
					</Alert>
				</div>




			</div>
		);
	}



}