module.exports = [
  {
    _id: '5ce459111332c87635c025b6',
    name: 'Zebraaa Way',
    cameraId: '3bf140f2-c39f-4a1a-938a-cef59bb790af',
    userId: '5ce458ff1332c87635c025b5',
    createdAt: '2019-05-21T20:01:21.741Z',
  },
  {
    _id: '5ce62bf91332e87635c025bb',
    name: 'Bolder Road',
    cameraId: 'a86dae2d-265f-440f-aacf-996c2b93248c',
    userId: '5ce458ff1332c87635c025b5',
    createdAt: '2019-05-26T14:45:35.667Z',
  },
  {
    _id: '5ce62bf91332e87635c0assdas',
    name: 'bla blah',
    cameraId: 'a86dae2d-265f-440f-aacf-996c2b93248c',
    userId: '5ce458ff1332c87635c025b5',
    createdAt: '2020-01-26T14:45:35.667Z',
  },
  {
    _id: '5ce62bf91332e87635c021212121',
    name: 'Fake camera',
    cameraId: 'a86dae2d-265f-440f-aacf-996c2b93248c',
    userId: '5ce458ff1332c87635c025b5',
    createdAt: '2019-05-26T14:45:35.667Z',
  },
]
