import React, { useState } from 'react';

import { NavLink, Redirect} from 'react-router-dom';
import { authService } from 'services';

import {
	Button
} from 'react-bootstrap';



import './navbar.scss';


export default function AppNavbar({ isDarkModeEnabled }) {
	const [goToLogin, updateGoToLogin] = useState(false);

 	function onLogoutClicked() {
		authService.removeToken();
		updateGoToLogin(true);
	}

	if (goToLogin) {
		return <Redirect to={{
			pathname: "/login",
			state: { message: 'Successfully Logged Out.', messageVariant: 'success' }
		}} />
	}


	else return (
		<nav className="navbar">
			<div className="container navbar-container">

			<div className="navbar-logo">
				<span>ZebraSafety </span>

			</div>

			<div className="navbar-content">
				<div className="content content-left">
					<NavLink exact to="/home" activeClassName="active">Home</NavLink>

				</div>

				<div className="content content-right">
					<Button variant="success" className="btn-sm" onClick={onLogoutClicked}>Logout</Button>
				</div>



			</div>







			</div>
		</nav>




	);
}
