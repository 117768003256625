import React, { Component } from 'react';
import { Navbar, Home, Camera, DataEvent } from 'components';
import {
	Route
} from 'react-router-dom';

// import { Home } from 'components/home/Home';




export default class Dashboard extends Component {
	constructor({ match }) {
		super();

		this.match = match;
	}

	componentDidMount() {

	}


	render() {
		const currentPath = this.match.path

		return (
			<div className="dashboard">
				<Navbar />
				<div>
					<Route path={currentPath} exact component={Home} ></Route>
					<Route path={`${currentPath}/:cameraId`} exact component={Camera} ></Route>
					<Route path={`${currentPath}/:cameraId/:dataEventId`} exact component={DataEvent} ></Route>
				</div>
			</div>
		)




	}




}
