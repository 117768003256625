function throttle(msToWait, func) {
	let inThrottle;

	return (...args) => {
		if (!inThrottle) {
			func(...args);
			inThrottle = true;
			setTimeout(() => inThrottle = false, msToWait);
		}
	}
}

// return error message string if invalid
// or undefined if valid.  this is to be used by formik
function validateEmail(email) {
	if (!email) return 'email is a required field';
	// simple email regex
	const emailRegex = /\S+@\S+\.\S+/;

	if (!emailRegex.test(email)) return 'Please enter a valid email address'
}


export { throttle, validateEmail }