import { apiService } from 'services';

export default class CameraService {

	getDataEvent(cameraId, dataEventId) {
		const url = `/cameras/${cameraId}/dataEvents/${dataEventId}`;

		return apiService.get(url);
	}

}