import axios from 'axios';
// import { Redirect, hashHistory } from 'react-router-dom';

import { storageService } from 'services';

export default class ApiService {
	constructor(baseUrl) {
		const devBaseUrl = 'http://localhost:4500/api/v1';
		const prodBaseUrl = 'https://api.zebrasafety.com/api/v1';

		baseUrl = process.env.NODE_ENV === 'development' ? devBaseUrl : prodBaseUrl;

		const token = storageService.getItem('token');

		this.defaultAxiosConfig = {
			baseURL: baseUrl,
			headers: { Authorization: token }
		}

		this.axiosInstance = axios.create(this.defaultAxiosConfig);
		this.axiosInstance.interceptors.response.use(response => response, error => {
			// for stuff like network error or other edge cases, axios won't get a error response back
			if (!error.response) return Promise.reject(error);

			// error.response is what we want here
			const statusCode = error.response.status;

			// the response message sent from server is what we care about
			const errorData = error.response.data;

			// ignore 401s received on these paths, shouldn't redirect for those
			const pathsToIgnore = ['/login', '/register'];

			// if unauthorized, means we need to redirect to login screen
			if (statusCode === 401  && !pathsToIgnore.includes(window.location.pathname)) {
				// TODO: better way to redirect?
				window.location.href="/login"
			}

			return Promise.reject(errorData);
		})
	}

	get(url) {
		return this.axiosInstance.get(url);
	}

	post(url, requestData) {
		return this.axiosInstance.post(url, requestData)
	}

	put(url, requestData) {
		return this.axiosInstance.put(url, requestData)
	}



	// used when updating instance to include auth token on every request, etc
	updateAxiosInstance(propsToAdd) {
		this.axiosInstance = axios.create({ ...this.defaultAxiosConfig, ...propsToAdd });
	}







	getToken() {
		return storageService.getItem('token');
	}



}