import React, { Component } from 'react';
import { ReactQueryConfigProvider } from 'react-query';

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import 'styles/app.scss';

import { Login, Dashboard, Register, VerifyEmail } from 'components';

import { authService } from 'services';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

library.add(faLongArrowAltRight);

const reactQueryConfig = {
  queries: {
    refetchOnWindowFocus: true, // this is the default anyways, just testing this out
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isDarkModeEnabled: true };

    this.authTokenExists = authService.getToken() || false;
  }

  render() {
    return (
      <Router>
        <ReactQueryConfigProvider config={reactQueryConfig}>
          <div className="App">
            <Route
              exact
              path="/"
              render={() =>
                // <Redirect to="/home" />
                authService.getToken() ? <Redirect to="/home" /> : <Redirect to="/login" />
              }
            />

            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/verifyEmail" component={VerifyEmail} />

            <PrivateRoute path="/home" component={Dashboard} tokenExists={this.authTokenExists} />
          </div>
        </ReactQueryConfigProvider>
      </Router>
    );
  }
}

// For now, private route forbids entry if token doesn't even exist in local storage
function PrivateRoute({ component: Component, tokenExists, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authService.getToken() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default App;
