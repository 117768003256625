import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Alert } from 'react-bootstrap';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';

import { authService } from 'services';
import './register.scss';
import { validateEmail } from 'utils';


export default class Register extends Component {
	constructor(props) {
		super(props);

		const defaultState = {
			message: '',
			messageVariant: '',
			showAlert: true,
			// when successful register, navigate to home page
			shouldNavigateHome: false,
		};


		this.state = defaultState;
		this.history = props.history;
	};


	render() {
		const registerSchema = Yup.object().shape({
			firstName: Yup.string().required(),
			lastName: Yup.string().required(),
			password: Yup.string().required()
		});


		if (this.state.shouldNavigateHome) {
			return <Redirect to="/home" />
		}

		else return (
			<div className="register-container container">
				<h1>Please Register</h1>

				<div className="alert-container">
					<Alert
						variant={this.state.messageVariant} dismissible
						show={this.state.message.length > 0 && this.state.showAlert}
						onClose={() => this.setState({ showAlert: false })}
					>
						<span>{this.state.message}</span>
					</Alert>
				</div>


				<Formik
					validationSchema={registerSchema}
					onSubmit={this.onFormSubmit}
					initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
				>
					{({
						handleSubmit,
						isValid,
						isSubmitting,
					}) => (
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<label >First name</label>
									<Field type="text" name="firstName" className="form-control" placeholder="Enter First Name" disabled={isSubmitting} />
									<ErrorMessage name="firstName">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
								</div>
								<div className="form-group">
									<label >Last name</label>
									<Field type="text" name="lastName" className="form-control" placeholder="Enter Last Name" disabled={isSubmitting} />
									<ErrorMessage name="lastName">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
								</div>

								<div className="form-group">
									<label >Email address</label>
									<Field type="email" name="email" className="form-control" placeholder="Enter Email" validate={validateEmail} disabled={isSubmitting} />
									<ErrorMessage name="email">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
								</div>

								<div className="form-group">
									<label >Password</label>
									<Field type="password" name="password" className="form-control" placeholder="Password" disabled={isSubmitting} />
									<ErrorMessage name="password">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
								</div>

								<button type="submit" className="btn btn-primary register-button" disabled={!isValid || isSubmitting}>
									<Spinner as="span" size="sm" aria-hidden="true" animation="border" className={isSubmitting ? '' : 'hidden' } />

									{ isSubmitting ? 'Saving..' : 'Submit' }
								</button>
							</form>
						)}
				</Formik>
				<div className="register-wrapper">
					<p className="text-muted">Aready have an account? <Link to="/login">Login here.</Link></p>

				</div>
			</div>
		)

	}

	onFormSubmit = (user, formMethods) => {
		this.setState({ showAlert: false });
		authService.register(user)
		.then(({ response }) => {
			const registrationMessage = 'You have registered successfully.  Please check your email for a verification link which is necessary to proceed.';

			this.setState({ message: registrationMessage, showAlert: true, messageVariant: 'success' });
		})
		.catch(err => {
			this.setState({ message: err.message, showAlert: true, messageVariant: 'warning' })
		})
		.finally(_ => formMethods.setSubmitting(false))
	}

	updateregisterValue = (propName) => (event) => {
		this.setState({ [propName]: event.target.value });
	}

}

