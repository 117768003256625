import './polyfill.js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker'

const isDevEnv = process.env.NODE_ENV === 'development';
// whether or not to use mock service workers
// this is toggleable in .env file
// when enabled, will intercept api requests
const useMsw = process.env.REACT_APP_USE_MSW === 'true';

if (isDevEnv && useMsw) {
  console.log('start worker');
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
