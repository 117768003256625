import { storageService, apiService } from 'services';

export default class AuthService {

	login(email, password) {
		const url = '/auth/login';
		return apiService.post(url, { email, password });
	}

	register(user) {
		// used to mock behavior
		// return new Promise((resolve, reject) => {
		// 	setTimeout(() => resolve({}), 2000);
		// })

		const url = '/auth/register';
		return apiService.post(url, user);
	}

	// this method both saves the token to local storage
	// and updates api service to have token included in all subsequent requests (axios instance)
	saveToken(token) {
		storageService.setItem('token', token);

		apiService.updateAxiosInstance({ headers: { Authorization: token } });
	}



	getToken() {
		return storageService.getItem('token');
	}

	removeToken() {
		storageService.removeItem('token');
	}



}