import StorageService from './StorageService';
import AuthService from './AuthService';
import ApiService from './ApiService';
import CameraService from './CameraService';
import DataEventService from './DataEventService';
import UserService from './UserService';


const storageService = new StorageService();
const authService = new AuthService();
const apiService = new ApiService();
const cameraService = new CameraService();
const dataEventService = new DataEventService();
const userService = new UserService();


export {
	storageService,
	authService,
	apiService,
	cameraService,
	dataEventService,
	userService,
};
