module.exports = [
  {
    _id: '5cef0bb2ebefc2541cd3b495',
    trusted: true,
    createdAt: '2019-05-31T06:08:09.897Z',
    seen: true,
    plateId: 'DH659',
    vehicleType: 'unknown',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1008284.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1008281.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1008282.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1008283.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1008284.jpg' },
    ],
  },
  {
    _id: '5cef0af2ebefc253a2ed0ba3',
    trusted: true,
    createdAt: '2019-05-31T02:23:56.465Z',
    seen: true,
    plateId: 'UNKNOWN',
    vehicleType: '3763388',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'outbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_428020.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428002.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428003.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428004.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428005.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428006.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428018.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428019.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428020.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_428021.jpg' },
    ],
  },
  {
    _id: '5cef0b54ebefc253dd8fb44e',
    trusted: true,
    createdAt: '2019-05-31T01:12:17.354Z',
    seen: true,
    plateId: 'J73956',
    vehicleType: 'unknown',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1003161.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003155.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003158.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003160.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003161.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003162.jpg' },
    ],
  },
  {
    _id: '5cef0bb4ebefc2541cd3b4a2',
    trusted: true,
    createdAt: '2019-05-30T11:51:09.178Z',
    seen: true,
    plateId: '724SC4',
    vehicleType: 'truck',
    vehicleColor: 'green',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'outbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1009135.jpg',
    rawImages: [{ image: 'https://s3.amazonaws.com/zebrasafety/file_1009135.jpg' }],
  },
  {
    _id: '5cef0b54ebefc253dd8fb450',
    trusted: true,
    createdAt: '2019-05-30T06:27:02.571Z',
    seen: true,
    plateId: '856VD4',
    vehicleType: 'unknown',
    vehicleColor: 'silver',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1003205.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003202.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003203.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003204.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003205.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003206.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003207.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003208.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003209.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003210.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1003211.jpg' },
    ],
  },
  {
    _id: '5cef0bb4ebefc2541cd3b4a6',
    trusted: true,
    createdAt: '2019-05-30T03:58:09.195Z',
    seen: true,
    plateId: '5C2430',
    vehicleType: 'truck',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'outbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1009440.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009438.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009439.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009440.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009441.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009443.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009444.jpg' },
    ],
  },
  {
    _id: '5cef0bb2ebefc2541cd3b492',
    trusted: true,
    createdAt: '2019-05-28T20:13:26.136Z',
    seen: true,
    plateId: '922355',
    vehicleType: 'unknown',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1000784.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000781.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000783.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000784.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000787.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000788.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000790.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000791.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000793.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1000794.jpg' },
    ],
  },
  {
    _id: '5cef0bb4ebefc2541cd3b4ab',
    trusted: true,
    createdAt: '2019-05-28T12:21:47.271Z',
    seen: true,
    plateId: '68CD87',
    vehicleType: 'unknown',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_1009777.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009774.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009775.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009776.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009777.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009778.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009780.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009781.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009782.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009783.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009784.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009785.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009786.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1009787.jpg' },
    ],
  },
  {
    _id: '5cec405505ef4a306c5cd7ba',
    trusted: true,
    createdAt: '2019-05-28T11:49:00.946Z',
    seen: true,
    plateId: '858YFK',
    vehicleType: 'unknown',
    vehicleColor: 'white',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_2778004.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1067378.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1067379.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1067380.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172101.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172102.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172103.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172104.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1721083.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1721084.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1721085.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172163.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172164.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172166.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172167.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1722190.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1722191.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1722192.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1722194.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_1722195.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_171.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_172.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_173.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_174.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_175.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_176.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_177.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_178.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_179.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_180.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_181.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_182.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_263.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_266.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_268.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_269.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2778001.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2778002.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2778003.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2778004.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2778005.jpg' },
    ],
  },
  {
    _id: '5cef0af2ebefc253a2ed0ba0',
    trusted: true,
    createdAt: '2019-05-28T06:05:08.772Z',
    seen: true,
    plateId: '794TVC',
    vehicleType: 'unknown',
    vehicleColor: 'blue_green',
    __v: 0,
    cameraId: '5ce62bf91332e87635c025bb',
    direction: 'inbound',
    imageUrl: 'https://s3.amazonaws.com/zebrasafety/file_2781869.jpg',
    rawImages: [
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781864.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781865.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781867.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781868.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781869.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781870.jpg' },
      { image: 'https://s3.amazonaws.com/zebrasafety/file_2781871.jpg' },
    ],
  },
];
